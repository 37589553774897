import * as _ from 'lodash';
import dayjs from 'dayjs';

const helpers = {
  computed: {
    countries() {
      return this.countriesList.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    states() {
      return this.statesList.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    timezones() {
      return this.timezonesList.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
  data() {
    return {
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      timezonesList: [
        {
          offset: 'GMT-12:00',
          name: 'Etc/GMT-12',
        },
        {
          offset: 'GMT-11:00',
          name: 'Etc/GMT-11',
        },
        {
          offset: 'GMT-11:00',
          name: 'Pacific/Midway',
        },
        {
          offset: 'GMT-10:00',
          name: 'America/Adak',
        },
        {
          offset: 'GMT-09:00',
          name: 'America/Anchorage',
        },
        {
          offset: 'GMT-09:00',
          name: 'Pacific/Gambier',
        },
        {
          offset: 'GMT-08:00',
          name: 'America/Dawson_Creek',
        },
        {
          offset: 'GMT-08:00',
          name: 'America/Ensenada',
        },
        {
          offset: 'GMT-08:00',
          name: 'America/Los_Angeles',
        },
        {
          offset: 'GMT-07:00',
          name: 'America/Chihuahua',
        },
        {
          offset: 'GMT-07:00',
          name: 'America/Denver',
        },
        {
          offset: 'GMT-06:00',
          name: 'America/Belize',
        },
        {
          offset: 'GMT-06:00',
          name: 'America/Cancun',
        },
        {
          offset: 'GMT-06:00',
          name: 'America/Chicago',
        },
        {
          offset: 'GMT-06:00',
          name: 'Chile/EasterIsland',
        },
        {
          offset: 'GMT-05:00',
          name: 'America/Bogota',
        },
        {
          offset: 'GMT-05:00',
          name: 'America/Havana',
        },
        {
          offset: 'GMT-05:00',
          name: 'America/New_York',
        },
        {
          offset: 'GMT-04:30',
          name: 'America/Caracas',
        },
        {
          offset: 'GMT-04:00',
          name: 'America/Campo_Grande',
        },
        {
          offset: 'GMT-04:00',
          name: 'America/Glace_Bay',
        },
        {
          offset: 'GMT-04:00',
          name: 'America/Goose_Bay',
        },
        {
          offset: 'GMT-04:00',
          name: 'America/Santiago',
        },
        {
          offset: 'GMT-04:00',
          name: 'America/La_Paz',
        },
        {
          offset: 'GMT-03:00',
          name: 'America/Argentina/Buenos_Aires',
        },
        {
          offset: 'GMT-03:00',
          name: 'America/Montevideo',
        },
        {
          offset: 'GMT-03:00',
          name: 'America/Araguaina',
        },
        {
          offset: 'GMT-03:00',
          name: 'America/Godthab',
        },
        {
          offset: 'GMT-03:00',
          name: 'America/Miquelon',
        },
        {
          offset: 'GMT-03:00',
          name: 'America/Sao_Paulo',
        },
        {
          offset: 'GMT-03:30',
          name: 'America/St_Johns',
        },
        {
          offset: 'GMT-02:00',
          name: 'America/Noronha',
        },
        {
          offset: 'GMT-01:00',
          name: 'Atlantic/Cape_Verde',
        },
        {
          offset: 'GMT',
          name: 'Europe/Belfast',
        },
        {
          offset: 'GMT',
          name: 'Africa/Abidjan',
        },
        {
          offset: 'GMT',
          name: 'Europe/Dublin',
        },
        {
          offset: 'GMT',
          name: 'Europe/Lisbon',
        },
        {
          offset: 'GMT',
          name: 'Europe/London',
        },
        {
          offset: 'UTC',
          name: 'UTC',
        },
        {
          offset: 'GMT+01:00',
          name: 'Africa/Algiers',
        },
        {
          offset: 'GMT+01:00',
          name: 'Africa/Windhoek',
        },
        {
          offset: 'GMT+01:00',
          name: 'Atlantic/Azores',
        },
        {
          offset: 'GMT+01:00',
          name: 'Atlantic/Stanley',
        },
        {
          offset: 'GMT+01:00',
          name: 'Europe/Amsterdam',
        },
        {
          offset: 'GMT+01:00',
          name: 'Europe/Belgrade',
        },
        {
          offset: 'GMT+01:00',
          name: 'Europe/Brussels',
        },
        {
          offset: 'GMT+02:00',
          name: 'Africa/Cairo',
        },
        {
          offset: 'GMT+02:00',
          name: 'Africa/Blantyre',
        },
        {
          offset: 'GMT+02:00',
          name: 'Asia/Beirut',
        },
        {
          offset: 'GMT+02:00',
          name: 'Asia/Damascus',
        },
        {
          offset: 'GMT+02:00',
          name: 'Asia/Gaza',
        },
        {
          offset: 'GMT+02:00',
          name: 'Asia/Jerusalem',
        },
        {
          offset: 'GMT+03:00',
          name: 'Africa/Addis_Ababa',
        },
        {
          offset: 'GMT+03:00',
          name: 'Asia/Riyadh89',
        },
        {
          offset: 'GMT+03:00',
          name: 'Europe/Minsk',
        },
        {
          offset: 'GMT+03:30',
          name: 'Asia/Tehran',
        },
        {
          offset: 'GMT+04:00',
          name: 'Asia/Dubai',
        },
        {
          offset: 'GMT+04:00',
          name: 'Asia/Yerevan',
        },
        {
          offset: 'GMT+04:00',
          name: 'Europe/Moscow',
        },
        {
          offset: 'GMT+04:30',
          name: 'Asia/Kabul',
        },
        {
          offset: 'GMT+05:00',
          name: 'Asia/Tashkent',
        },
        {
          offset: 'GMT+05:30',
          name: 'Asia/Kolkata',
        },
        {
          offset: 'GMT+05:45',
          name: 'Asia/Katmandu',
        },
        {
          offset: 'GMT+06:00',
          name: 'Asia/Dhaka',
        },
        {
          offset: 'GMT+06:00',
          name: 'Asia/Yekaterinburg',
        },
        {
          offset: 'GMT+06:30',
          name: 'Asia/Rangoon',
        },
        {
          offset: 'GMT+07:00',
          name: 'Asia/Bangkok',
        },
        {
          offset: 'GMT+07:00',
          name: 'Asia/Novosibirsk',
        },
        {
          offset: 'GMT+08:00',
          name: 'Etc/GMT+8',
        },
        {
          offset: 'GMT+08:00',
          name: 'Asia/Hong_Kong',
        },
        {
          offset: 'GMT+08:00',
          name: 'Asia/Krasnoyarsk',
        },
        {
          offset: 'GMT+08:00',
          name: 'Australia/Perth',
        },
        {
          offset: 'GMT+08:45',
          name: 'Australia/Eucla',
        },
        {
          offset: 'GMT+09:00',
          name: 'Asia/Irkutsk',
        },
        {
          offset: 'GMT+09:00',
          name: 'Asia/Seoul',
        },
        {
          offset: 'GMT+09:00',
          name: 'Asia/Tokyo',
        },
        {
          offset: 'GMT+09:30',
          name: 'Australia/Adelaide',
        },
        {
          offset: 'GMT+09:30',
          name: 'Australia/Darwin',
        },
        {
          offset: 'GMT+09:30',
          name: 'Pacific/Marquesas',
        },
        {
          offset: 'GMT+10:00',
          name: 'Etc/GMT+10',
        },
        {
          offset: 'GMT+10:00',
          name: 'Australia/Brisbane',
        },
        {
          offset: 'GMT+10:00',
          name: 'Australia/Hobart',
        },
        {
          offset: 'GMT+10:00',
          name: 'Asia/Yakutsk',
        },
        {
          offset: 'GMT+10:30',
          name: 'Australia/Lord_Howe',
        },
        {
          offset: 'GMT+11:00',
          name: 'Asia/Vladivostok',
        },
        {
          offset: 'GMT+11:30',
          name: 'Pacific/Norfolk',
        },
        {
          offset: 'GMT+12:00',
          name: 'Etc/GMT+12',
        },
        {
          offset: 'GMT+12:00',
          name: 'Asia/Anadyr',
        },
        {
          offset: 'GMT+12:00',
          name: 'Asia/Magadan',
        },
        {
          offset: 'GMT+12:00',
          name: 'Pacific/Auckland',
        },
        {
          offset: 'GMT+12:45',
          name: 'Pacific/Chatham',
        },
        {
          offset: 'GMT+13:00',
          name: 'Pacific/Tongatapu',
        },
        {
          offset: 'GMT+14:00',
          name: 'Pacific/Kiritimati',
        },
      ],
      countriesList: [
        {
          code: 'AF',
          name: 'Afghanistan',
        },
        {
          code: 'AX',
          name: 'Aland Islands',
        },
        {
          code: 'AL',
          name: 'Albania',
        },
        {
          code: 'DZ',
          name: 'Algeria',
        },
        {
          code: 'AS',
          name: 'American Samoa',
        },
        {
          code: 'AD',
          name: 'Andorra',
        },
        {
          code: 'AO',
          name: 'Angola',
        },
        {
          code: 'AI',
          name: 'Anguilla',
        },
        {
          code: 'AQ',
          name: 'Antarctica',
        },
        {
          code: 'AG',
          name: 'Antigua And Barbuda',
        },
        {
          code: 'AR',
          name: 'Argentina',
        },
        {
          code: 'AM',
          name: 'Armenia',
        },
        {
          code: 'AW',
          name: 'Aruba',
        },
        {
          code: 'AU',
          name: 'Australia',
        },
        {
          code: 'AT',
          name: 'Austria',
        },
        {
          code: 'AZ',
          name: 'Azerbaijan',
        },
        {
          code: 'BS',
          name: 'Bahamas',
        },
        {
          code: 'BH',
          name: 'Bahrain',
        },
        {
          code: 'BD',
          name: 'Bangladesh',
        },
        {
          code: 'BB',
          name: 'Barbados',
        },
        {
          code: 'BY',
          name: 'Belarus',
        },
        {
          code: 'BE',
          name: 'Belgium',
        },
        {
          code: 'BZ',
          name: 'Belize',
        },
        {
          code: 'BJ',
          name: 'Benin',
        },
        {
          code: 'BM',
          name: 'Bermuda',
        },
        {
          code: 'BT',
          name: 'Bhutan',
        },
        {
          code: 'BO',
          name: 'Bolivia',
        },
        {
          code: 'BA',
          name: 'Bosnia And Herzegovina',
        },
        {
          code: 'BW',
          name: 'Botswana',
        },
        {
          code: 'BV',
          name: 'Bouvet Island',
        },
        {
          code: 'BR',
          name: 'Brazil',
        },
        {
          code: 'IO',
          name: 'British Indian Ocean Territory',
        },
        {
          code: 'BN',
          name: 'Brunei Darussalam',
        },
        {
          code: 'BG',
          name: 'Bulgaria',
        },
        {
          code: 'BF',
          name: 'Burkina Faso',
        },
        {
          code: 'BI',
          name: 'Burundi',
        },
        {
          code: 'KH',
          name: 'Cambodia',
        },
        {
          code: 'CM',
          name: 'Cameroon',
        },
        {
          code: 'CA',
          name: 'Canada',
        },
        {
          code: 'CV',
          name: 'Cape Verde',
        },
        {
          code: 'KY',
          name: 'Cayman Islands',
        },
        {
          code: 'CF',
          name: 'Central African Republic',
        },
        {
          code: 'TD',
          name: 'Chad',
        },
        {
          code: 'CL',
          name: 'Chile',
        },
        {
          code: 'CN',
          name: 'China',
        },
        {
          code: 'CX',
          name: 'Christmas Island',
        },
        {
          code: 'CC',
          name: 'Cocos (Keeling) Islands',
        },
        {
          code: 'CO',
          name: 'Colombia',
        },
        {
          code: 'KM',
          name: 'Comoros',
        },
        {
          code: 'CG',
          name: 'Congo',
        },
        {
          code: 'CD',
          name: 'Congo, Democratic Republic',
        },
        {
          code: 'CK',
          name: 'Cook Islands',
        },
        {
          code: 'CR',
          name: 'Costa Rica',
        },
        {
          code: 'CI',
          name: "Cote D'Ivoire",
        },
        {
          code: 'HR',
          name: 'Croatia',
        },
        {
          code: 'CU',
          name: 'Cuba',
        },
        {
          code: 'CY',
          name: 'Cyprus',
        },
        {
          code: 'CZ',
          name: 'Czech Republic',
        },
        {
          code: 'DK',
          name: 'Denmark',
        },
        {
          code: 'DJ',
          name: 'Djibouti',
        },
        {
          code: 'DM',
          name: 'Dominica',
        },
        {
          code: 'DO',
          name: 'Dominican Republic',
        },
        {
          code: 'EC',
          name: 'Ecuador',
        },
        {
          code: 'EG',
          name: 'Egypt',
        },
        {
          code: 'SV',
          name: 'El Salvador',
        },
        {
          code: 'GQ',
          name: 'Equatorial Guinea',
        },
        {
          code: 'ER',
          name: 'Eritrea',
        },
        {
          code: 'EE',
          name: 'Estonia',
        },
        {
          code: 'ET',
          name: 'Ethiopia',
        },
        {
          code: 'FK',
          name: 'Falkland Islands (Malvinas)',
        },
        {
          code: 'FO',
          name: 'Faroe Islands',
        },
        {
          code: 'FJ',
          name: 'Fiji',
        },
        {
          code: 'FI',
          name: 'Finland',
        },
        {
          code: 'FR',
          name: 'France',
        },
        {
          code: 'GF',
          name: 'French Guiana',
        },
        {
          code: 'PF',
          name: 'French Polynesia',
        },
        {
          code: 'TF',
          name: 'French Southern Territories',
        },
        {
          code: 'GA',
          name: 'Gabon',
        },
        {
          code: 'GM',
          name: 'Gambia',
        },
        {
          code: 'GE',
          name: 'Georgia',
        },
        {
          code: 'DE',
          name: 'Germany',
        },
        {
          code: 'GH',
          name: 'Ghana',
        },
        {
          code: 'GI',
          name: 'Gibraltar',
        },
        {
          code: 'GR',
          name: 'Greece',
        },
        {
          code: 'GL',
          name: 'Greenland',
        },
        {
          code: 'GD',
          name: 'Grenada',
        },
        {
          code: 'GP',
          name: 'Guadeloupe',
        },
        {
          code: 'GU',
          name: 'Guam',
        },
        {
          code: 'GT',
          name: 'Guatemala',
        },
        {
          code: 'GG',
          name: 'Guernsey',
        },
        {
          code: 'GN',
          name: 'Guinea',
        },
        {
          code: 'GW',
          name: 'Guinea-Bissau',
        },
        {
          code: 'GY',
          name: 'Guyana',
        },
        {
          code: 'HT',
          name: 'Haiti',
        },
        {
          code: 'HM',
          name: 'Heard Island & Mcdonald Islands',
        },
        {
          code: 'VA',
          name: 'Holy See (Vatican City State)',
        },
        {
          code: 'HN',
          name: 'Honduras',
        },
        {
          code: 'HK',
          name: 'Hong Kong',
        },
        {
          code: 'HU',
          name: 'Hungary',
        },
        {
          code: 'IS',
          name: 'Iceland',
        },
        {
          code: 'IN',
          name: 'India',
        },
        {
          code: 'ID',
          name: 'Indonesia',
        },
        {
          code: 'IR',
          name: 'Iran, Islamic Republic Of',
        },
        {
          code: 'IQ',
          name: 'Iraq',
        },
        {
          code: 'IE',
          name: 'Ireland',
        },
        {
          code: 'IM',
          name: 'Isle Of Man',
        },
        {
          code: 'IL',
          name: 'Israel',
        },
        {
          code: 'IT',
          name: 'Italy',
        },
        {
          code: 'JM',
          name: 'Jamaica',
        },
        {
          code: 'JP',
          name: 'Japan',
        },
        {
          code: 'JE',
          name: 'Jersey',
        },
        {
          code: 'JO',
          name: 'Jordan',
        },
        {
          code: 'KZ',
          name: 'Kazakhstan',
        },
        {
          code: 'KE',
          name: 'Kenya',
        },
        {
          code: 'KI',
          name: 'Kiribati',
        },
        {
          code: 'KR',
          name: 'Korea',
        },
        {
          code: 'KW',
          name: 'Kuwait',
        },
        {
          code: 'KG',
          name: 'Kyrgyzstan',
        },
        {
          code: 'LA',
          name: "Lao People's Democratic Republic",
        },
        {
          code: 'LV',
          name: 'Latvia',
        },
        {
          code: 'LB',
          name: 'Lebanon',
        },
        {
          code: 'LS',
          name: 'Lesotho',
        },
        {
          code: 'LR',
          name: 'Liberia',
        },
        {
          code: 'LY',
          name: 'Libyan Arab Jamahiriya',
        },
        {
          code: 'LI',
          name: 'Liechtenstein',
        },
        {
          code: 'LT',
          name: 'Lithuania',
        },
        {
          code: 'LU',
          name: 'Luxembourg',
        },
        {
          code: 'MO',
          name: 'Macao',
        },
        {
          code: 'MK',
          name: 'Macedonia',
        },
        {
          code: 'MG',
          name: 'Madagascar',
        },
        {
          code: 'MW',
          name: 'Malawi',
        },
        {
          code: 'MY',
          name: 'Malaysia',
        },
        {
          code: 'MV',
          name: 'Maldives',
        },
        {
          code: 'ML',
          name: 'Mali',
        },
        {
          code: 'MT',
          name: 'Malta',
        },
        {
          code: 'MH',
          name: 'Marshall Islands',
        },
        {
          code: 'MQ',
          name: 'Martinique',
        },
        {
          code: 'MR',
          name: 'Mauritania',
        },
        {
          code: 'MU',
          name: 'Mauritius',
        },
        {
          code: 'YT',
          name: 'Mayotte',
        },
        {
          code: 'MX',
          name: 'Mexico',
        },
        {
          code: 'FM',
          name: 'Micronesia, Federated States Of',
        },
        {
          code: 'MD',
          name: 'Moldova',
        },
        {
          code: 'MC',
          name: 'Monaco',
        },
        {
          code: 'MN',
          name: 'Mongolia',
        },
        {
          code: 'ME',
          name: 'Montenegro',
        },
        {
          code: 'MS',
          name: 'Montserrat',
        },
        {
          code: 'MA',
          name: 'Morocco',
        },
        {
          code: 'MZ',
          name: 'Mozambique',
        },
        {
          code: 'MM',
          name: 'Myanmar',
        },
        {
          code: 'NA',
          name: 'Namibia',
        },
        {
          code: 'NR',
          name: 'Nauru',
        },
        {
          code: 'NP',
          name: 'Nepal',
        },
        {
          code: 'NL',
          name: 'Netherlands',
        },
        {
          code: 'AN',
          name: 'Netherlands Antilles',
        },
        {
          code: 'NC',
          name: 'New Caledonia',
        },
        {
          code: 'NZ',
          name: 'New Zealand',
        },
        {
          code: 'NI',
          name: 'Nicaragua',
        },
        {
          code: 'NE',
          name: 'Niger',
        },
        {
          code: 'NG',
          name: 'Nigeria',
        },
        {
          code: 'NU',
          name: 'Niue',
        },
        {
          code: 'NF',
          name: 'Norfolk Island',
        },
        {
          code: 'MP',
          name: 'Northern Mariana Islands',
        },
        {
          code: 'NO',
          name: 'Norway',
        },
        {
          code: 'OM',
          name: 'Oman',
        },
        {
          code: 'PK',
          name: 'Pakistan',
        },
        {
          code: 'PW',
          name: 'Palau',
        },
        {
          code: 'PS',
          name: 'Palestinian Territory, Occupied',
        },
        {
          code: 'PA',
          name: 'Panama',
        },
        {
          code: 'PG',
          name: 'Papua New Guinea',
        },
        {
          code: 'PY',
          name: 'Paraguay',
        },
        {
          code: 'PE',
          name: 'Peru',
        },
        {
          code: 'PH',
          name: 'Philippines',
        },
        {
          code: 'PN',
          name: 'Pitcairn',
        },
        {
          code: 'PL',
          name: 'Poland',
        },
        {
          code: 'PT',
          name: 'Portugal',
        },
        {
          code: 'PR',
          name: 'Puerto Rico',
        },
        {
          code: 'QA',
          name: 'Qatar',
        },
        {
          code: 'RE',
          name: 'Reunion',
        },
        {
          code: 'RO',
          name: 'Romania',
        },
        {
          code: 'RU',
          name: 'Russian Federation',
        },
        {
          code: 'RW',
          name: 'Rwanda',
        },
        {
          code: 'BL',
          name: 'Saint Barthelemy',
        },
        {
          code: 'SH',
          name: 'Saint Helena',
        },
        {
          code: 'KN',
          name: 'Saint Kitts And Nevis',
        },
        {
          code: 'LC',
          name: 'Saint Lucia',
        },
        {
          code: 'MF',
          name: 'Saint Martin',
        },
        {
          code: 'PM',
          name: 'Saint Pierre And Miquelon',
        },
        {
          code: 'VC',
          name: 'Saint Vincent And Grenadines',
        },
        {
          code: 'WS',
          name: 'Samoa',
        },
        {
          code: 'SM',
          name: 'San Marino',
        },
        {
          code: 'ST',
          name: 'Sao Tome And Principe',
        },
        {
          code: 'SA',
          name: 'Saudi Arabia',
        },
        {
          code: 'SN',
          name: 'Senegal',
        },
        {
          code: 'RS',
          name: 'Serbia',
        },
        {
          code: 'SC',
          name: 'Seychelles',
        },
        {
          code: 'SL',
          name: 'Sierra Leone',
        },
        {
          code: 'SG',
          name: 'Singapore',
        },
        {
          code: 'SK',
          name: 'Slovakia',
        },
        {
          code: 'SI',
          name: 'Slovenia',
        },
        {
          code: 'SB',
          name: 'Solomon Islands',
        },
        {
          code: 'SO',
          name: 'Somalia',
        },
        {
          code: 'ZA',
          name: 'South Africa',
        },
        {
          code: 'GS',
          name: 'South Georgia And Sandwich Isl.',
        },
        {
          code: 'ES',
          name: 'Spain',
        },
        {
          code: 'LK',
          name: 'Sri Lanka',
        },
        {
          code: 'SD',
          name: 'Sudan',
        },
        {
          code: 'SR',
          name: 'Suriname',
        },
        {
          code: 'SJ',
          name: 'Svalbard And Jan Mayen',
        },
        {
          code: 'SZ',
          name: 'Swaziland',
        },
        {
          code: 'SE',
          name: 'Sweden',
        },
        {
          code: 'CH',
          name: 'Switzerland',
        },
        {
          code: 'SY',
          name: 'Syrian Arab Republic',
        },
        {
          code: 'TW',
          name: 'Taiwan',
        },
        {
          code: 'TJ',
          name: 'Tajikistan',
        },
        {
          code: 'TZ',
          name: 'Tanzania',
        },
        {
          code: 'TH',
          name: 'Thailand',
        },
        {
          code: 'TL',
          name: 'Timor-Leste',
        },
        {
          code: 'TG',
          name: 'Togo',
        },
        {
          code: 'TK',
          name: 'Tokelau',
        },
        {
          code: 'TO',
          name: 'Tonga',
        },
        {
          code: 'TT',
          name: 'Trinidad And Tobago',
        },
        {
          code: 'TN',
          name: 'Tunisia',
        },
        {
          code: 'TR',
          name: 'Turkey',
        },
        {
          code: 'TM',
          name: 'Turkmenistan',
        },
        {
          code: 'TC',
          name: 'Turks And Caicos Islands',
        },
        {
          code: 'TV',
          name: 'Tuvalu',
        },
        {
          code: 'UG',
          name: 'Uganda',
        },
        {
          code: 'UA',
          name: 'Ukraine',
        },
        {
          code: 'AE',
          name: 'United Arab Emirates',
        },
        {
          code: 'GB',
          name: 'United Kingdom',
        },
        {
          code: 'US',
          name: 'United States',
        },
        {
          code: 'UM',
          name: 'United States Outlying Islands',
        },
        {
          code: 'UY',
          name: 'Uruguay',
        },
        {
          code: 'UZ',
          name: 'Uzbekistan',
        },
        {
          code: 'VU',
          name: 'Vanuatu',
        },
        {
          code: 'VE',
          name: 'Venezuela',
        },
        {
          code: 'VN',
          name: 'Vietnam',
        },
        {
          code: 'VG',
          name: 'Virgin Islands, British',
        },
        {
          code: 'VI',
          name: 'Virgin Islands, U.S.',
        },
        {
          code: 'WF',
          name: 'Wallis And Futuna',
        },
        {
          code: 'EH',
          name: 'Western Sahara',
        },
        {
          code: 'YE',
          name: 'Yemen',
        },
        {
          code: 'ZM',
          name: 'Zambia',
        },
        {
          code: 'ZW',
          name: 'Zimbabwe',
        },
      ],
      statesList: [
        {
          name: 'Alabama',
          code: 'AL',
        },
        {
          name: 'Alaska',
          code: 'AK',
        },
        {
          name: 'American Samoa',
          code: 'AS',
        },
        {
          name: 'Arizona',
          code: 'AZ',
        },
        {
          name: 'Arkansas',
          code: 'AR',
        },
        {
          name: 'California',
          code: 'CA',
        },
        {
          name: 'Colorado',
          code: 'CO',
        },
        {
          name: 'Connecticut',
          code: 'CT',
        },
        {
          name: 'Delaware',
          code: 'DE',
        },
        {
          name: 'District Of Columbia',
          code: 'DC',
        },
        {
          name: 'Federated States Of Micronesia',
          code: 'FM',
        },
        {
          name: 'Florida',
          code: 'FL',
        },
        {
          name: 'Georgia',
          code: 'GA',
        },
        {
          name: 'Guam',
          code: 'GU',
        },
        {
          name: 'Hawaii',
          code: 'HI',
        },
        {
          name: 'Idaho',
          code: 'ID',
        },
        {
          name: 'Illinois',
          code: 'IL',
        },
        {
          name: 'Indiana',
          code: 'IN',
        },
        {
          name: 'Iowa',
          code: 'IA',
        },
        {
          name: 'Kansas',
          code: 'KS',
        },
        {
          name: 'Kentucky',
          code: 'KY',
        },
        {
          name: 'Louisiana',
          code: 'LA',
        },
        {
          name: 'Maine',
          code: 'ME',
        },
        {
          name: 'Marshall Islands',
          code: 'MH',
        },
        {
          name: 'Maryland',
          code: 'MD',
        },
        {
          name: 'Massachusetts',
          code: 'MA',
        },
        {
          name: 'Michigan',
          code: 'MI',
        },
        {
          name: 'Minnesota',
          code: 'MN',
        },
        {
          name: 'Mississippi',
          code: 'MS',
        },
        {
          name: 'Missouri',
          code: 'MO',
        },
        {
          name: 'Montana',
          code: 'MT',
        },
        {
          name: 'Nebraska',
          code: 'NE',
        },
        {
          name: 'Nevada',
          code: 'NV',
        },
        {
          name: 'New Hampshire',
          code: 'NH',
        },
        {
          name: 'New Jersey',
          code: 'NJ',
        },
        {
          name: 'New Mexico',
          code: 'NM',
        },
        {
          name: 'New York',
          code: 'NY',
        },
        {
          name: 'North Carolina',
          code: 'NC',
        },
        {
          name: 'North Dakota',
          code: 'ND',
        },
        {
          name: 'Northern Mariana Islands',
          code: 'MP',
        },
        {
          name: 'Ohio',
          code: 'OH',
        },
        {
          name: 'Oklahoma',
          code: 'OK',
        },
        {
          name: 'Oregon',
          code: 'OR',
        },
        {
          name: 'Palau',
          code: 'PW',
        },
        {
          name: 'Pennsylvania',
          code: 'PA',
        },
        {
          name: 'Puerto Rico',
          code: 'PR',
        },
        {
          name: 'Rhode Island',
          code: 'RI',
        },
        {
          name: 'South Carolina',
          code: 'SC',
        },
        {
          name: 'South Dakota',
          code: 'SD',
        },
        {
          name: 'Tennessee',
          code: 'TN',
        },
        {
          name: 'Texas',
          code: 'TX',
        },
        {
          name: 'Utah',
          code: 'UT',
        },
        {
          name: 'Vermont',
          code: 'VT',
        },
        {
          name: 'Virgin Islands',
          code: 'VI',
        },
        {
          name: 'Virginia',
          code: 'VA',
        },
        {
          name: 'Washington',
          code: 'WA',
        },
        {
          name: 'West Virginia',
          code: 'WV',
        },
        {
          name: 'Wisconsin',
          code: 'WI',
        },
        {
          name: 'Wyoming',
          code: 'WY',
        },
      ],
    };
  },
  methods: {
    hasProperty(obj, prop) {
      return Object.prototype.hasOwnProperty.call(obj, prop);
    },
    slugify(string) {
      let slug = '';
      // Change to lower case
      let stringLower = string.toLowerCase();
      // Letter 'e'
      slug = stringLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      // Letter 'a'
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      // Letter 'o'
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      // Letter 'u'
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      // Letter 'd'
      slug = slug.replace(/đ/gi, 'd');
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '');
      // Change whitespace to '-'
      slug = slug.replace(/\s+/g, '-');

      // console.log('mixins helpers.js methods slugify() string slug', { string, slug });

      return slug;
    },
    stringify(items, value, glue, empty) {
      glue = glue || ', ';
      empty = empty || 'None';
      const itemsList = items.length ? items.map((item) => _.upperFirst(item[value])) : [empty];
      return itemsList.join(glue);
    },
    isInt(value) {
      const x = parseFloat(value);
      return !isNaN(value) && (x | 0) === x;
    },
    isDeleted(item) {
      if (this.hasProperty(item, 'deleted_at')) {
        return dayjs(item.deleted_at).isValid();
      }

      return false;
    },
    isSelected(a, b) {
      return a == b;
    },
  },
};

// leave the export, even if you don't use it
export default helpers;
